.container-fluid {
  padding: 0;
}
.date-container .print-content {
  display: inline-block;
}
.date-container > .print-content button {
  margin: 0 !important;
}

